import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import ServiceDetails from "@/components/service-details-website";
import CallToActionOne from "@/components/call-to-action-one";
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";
import HeaderOne from "@/components/header-one";
import SEO from "@/components/seo";

const WebsiteDevelopment = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <SEO
          title="Solxk - Zhvillimi i faqeve"
          description="Zbuloni shërbimet profesionale të zhvillimit të uebsajteve të Solxk Ne jemi të specializuar në ndërtimin e faqeve të internetit të fuqishme, të shkallëzuara dhe të sigurta të përshtatura për nevojat e zhvilluesve dhe bizneseve."
        />
        <Layout PageTitle="Zhvillimi i faqeve">
          <HeaderOne />
          <PageBanner title="Zhvillimi i faqeve" name="Service" />
          <ServiceDetails />
          <CallToActionOne extraClassName="ready" />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default WebsiteDevelopment;
