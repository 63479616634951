import React from "react";
import serviceS1 from "@/images/web.jpg";
import serviceS2 from "@/images/planingg.jpg";
const ServiceDetailsContent = () => {
  return (
    <div className="serviceArea">
      <img src={serviceS1} alt="" />
      <h2>Zhvillim i faqeve</h2>
      <p>
        Në Solxk ne jemi të përkushtuar për të dizajnuar interesante dhe
        dizajnimi funksional i përvojave në ueb që janë përshtatur saktësisht
        për përmbushjen Kërkesat e biznesit të përshtatura për dallimet tuaja
        Ekipi ynë zhvilluesit, projektuesit dhe strategët me përvojë punojnë me
        përpikëri së bashku për të sjellë në jetë vizionin tuaj. Pixels of
        Pixels.Ne jemi përpiqet të krijojë më shumë sesa thjesht faqe interneti
        Përvoja që angazhojnë, frymëzojnë dhe konvertojnë. Tonë The Qasja ndaj
        zhvillimit të uebit integron pa probleme liderët novatorë Teknologji me
        risi krijuese për të siguruar tuajën Prania në internet jo vetëm që bie
        në sy, por edhe jep rezultate të matshme.
      </p>
      <div className="row gaping">
        <div className="col-lg-6 col-sm-12 col-md-6">
          <img src={serviceS2} alt="" />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-6">
          <h3>Planifikimi dhe Strategjia</h3>
          <p>Shërbimet që ne ofrojmë:</p>
          <ul>
            <li>
              <i className="fa fa-check-square"></i>Zhvillimi i personalizuar i
              uebit
            </li>
            <li>
              <i className="fa fa-check-square"></i>E-Commerce-Solutions:
              Fuqizoni biznesin tuaj
            </li>
            <li>
              <i className="fa fa-check-square"></i>Dizajn Responsiv
            </li>
            <li>
              <i className="fa fa-check-square"></i>Sistemet e menaxhimit të
              përmbajtjes (CMS)
            </li>
            <li>
              <i className="fa fa-check-square"></i>Zhvillimi i aplikacioneve në
              ueb
            </li>
          </ul>
        </div>
      </div>
      <p>
        Pavarësisht nëse jeni një startup, keni një identitet të fortë marke set
        ose një kompani e themeluar që synon tuaj Për të përditësuar praninë
        tuaj vizuale, Solxk është këtu për t'ju ndihmuar për të ndihmuar. Na
        kontaktoni sot për të diskutuar dizajnin tuaj nevojave dhe hidhni hapin
        e parë për të transformuar markën tuaj në një transformuar kryevepër
        vizuale.
      </p>
      <p>
        Në Solxk ne e pranojmë se themeli i një suksesi Projekti i zhvillimit
        të Web-it qëndron në planifikimin e përpiktë.Përpara Shkrimi i një
        rreshti të vetëm kodi ose dizajnimi i një piksel të vetëm ne investojmë
        kohë për të kuptuar qëllimet e biznesit tuaj, Grupet e synuara dhe
        kërkesat tona gjithëpërfshirëse Procesi i planifikimit siguron që
        aspekti i projektit tuaj të trajtohet me kujdes merren parasysh dhe
        ekzekutohen me saktësi.
      </p>
    </div>
  );
};

export default ServiceDetailsContent;
